import React from 'react';
import clsx from 'clsx';

import {button, secondary, outline} from './styles/button.module.scss';

type Props = {
  children: string;
  id?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
  style?: React.CSSProperties;
  type?: 'button' | 'submit' | 'reset';
  isDisabled?: boolean;
  isSecondary?: boolean;
  isOutline?: boolean;
};

const Button = ({
  children,
  id,
  onClick,
  className,
  style,
  type,
  isDisabled,
  isSecondary,
  isOutline,
  ...props
}: Props) => (
  <button
    className={clsx(button, className, {
      [secondary]: isSecondary,
      [outline]: isOutline
    })}
    style={style}
    disabled={isDisabled}
    // eslint-disable-next-line react/button-has-type
    type={type}
    id={id}
    onClick={onClick}
    {...props}
  >
    {children}
  </button>
);

export default Button;
