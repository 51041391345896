import React from "react";
import { Router } from "@reach/router";

import Layout from "../components/layout";
import Home from "../components/tools-home";

import Reader from "../components/reader";
import WordHelper from "../components/word-helper-page";
import Writer from "../components/writer";
import Extension from "../components/extension";

const Tools = () => (
  <Layout
    title="Tools"
    isTitleDisplayed={false}
    isMarketingNavDisplayed={false}
  >
    <Router>
      <Reader path="/tools/reader" />
      <Writer path="/tools/writer" />
      <WordHelper path="/tools/wordHelper" />
      <WordHelper path="/tools/wordhelper" />
      <WordHelper path="/tools/wordhelper/:query" />
      <WordHelper path="/tools/wordHelper/:query" />
      <Extension path="/tools/extension" />
      <Home path="/tools" />
    </Router>
  </Layout>
);
export default Tools;
