import React from 'react';
import {Link} from 'gatsby';
import clsx from 'clsx';

import {button, outline, secondary} from './styles/button.module.scss';
import {
  iconButton,
  iconButtonIcon,
  iconButtonName
} from './styles/icon-button.module.scss';

type Props = {
  to: string;
  children: React.ReactNode[] | React.ReactNode;
  icon?: React.ReactNode;
  isSecondary?: boolean;
};

const IconButtonLink = ({to, children, icon, isSecondary, ...props}: Props) => (
  <Link className={iconButton} to={to} {...props}>
    <span className={iconButtonIcon}>{icon}</span>
    <span
      className={clsx(iconButtonName, button, outline, {
        [secondary]: isSecondary
      })}
    >
      {children}
    </span>
  </Link>
);

export default IconButtonLink;
