import React, {PropsWithChildren, useContext, useEffect, useRef} from 'react';
import clsx from 'clsx';
import {useMeasure} from 'react-use';
import Scrollbar from 'react-scrollbars-custom';
import {ElementPropsWithElementRef} from 'react-scrollbars-custom/dist/types/types';

import {AppContext} from './app-context';
import Close from '../content/icons/close.svg';
import ArcText from './arc-text';
import Toolbar from './toolbar';

import usePrintArcText from '../hooks/use-print-arc-text';
import useSelectWordOnClick from '../hooks/use-select-word-on-click';

import {
  reader,
  reader__embedded,
  reader__open,
  close,
  content
} from './styles/reader-overlay.module.scss';

type Props = {
  className?: string;
  isOpen?: boolean;
  isEmbedded?: boolean;
  hasWordHelperCallback?: boolean;
  closeReader?: () => any;
  text?: string;
};

const ReaderOverlay = ({
  className,
  isOpen,
  isEmbedded,
  hasWordHelperCallback,
  closeReader,
  text
}: Props) => {
  const textRef = useRef<HTMLDivElement>(null);

  const {userOptions} = useContext(AppContext);

  const scrollbarRef = useRef<Scrollbar>(null);

  const [containerRef, {width}] = useMeasure<HTMLDivElement>();

  const maxWidth = width > 0 ? width - 50 : 590;

  useSelectWordOnClick(textRef);

  const {doPrint, isPrintLoading} = usePrintArcText();

  useEffect(() => {
    scrollbarRef.current?.scrollToTop();
  }, [text, scrollbarRef]);

  return (
    <div
      ref={containerRef}
      id="readerOverlay"
      role={isEmbedded ? '' : 'dialog'}
      className={clsx(reader, className, {
        [reader__embedded]: isEmbedded,
        [reader__open]: isOpen
      })}
    >
      {!isEmbedded && (
        <button
          className={close}
          aria-label="Close reader"
          type="button"
          onClick={closeReader}
        >
          <Close/>
        </button>
      )}
      <Scrollbar
        ref={scrollbarRef}
        disableTracksWidthCompensation
        trackYProps={{
          renderer: ({
            elementRef: ref,
            style,
            ...rest
          }: PropsWithChildren<ElementPropsWithElementRef>) => {
            return (
              <span
                {...rest} ref={ref}
                style={{
                  ...style,
                  top: '4px',
                  right: '4px',
                  width: '8px',
                  height: 'calc(100% - 8px)',
                  background: 'none'}}/>
            );
          }
        }}
      >
        <div ref={textRef} className={content}>
          {text
            ?.split('\n')
            .filter((paragraph: string) => paragraph !== '')
            .map((paragraph: string, i: number) => (
              <ArcText
                // eslint-disable-next-line react/no-array-index-key
                key={`${paragraph}-${i}`}
                width={Math.min(userOptions?.width ?? maxWidth, maxWidth)}
              >
                {paragraph}
              </ArcText>
            ))}
        </div>
      </Scrollbar>
      <Toolbar
        hasWordHelperCallback={hasWordHelperCallback}
        isPrintLoading={isPrintLoading}
        onPrint={() => {
          doPrint(text);
        }}
      />
    </div>
  );
};

export default ReaderOverlay;
