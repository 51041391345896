import React, { useRef, useState } from "react";

import Button from "./button";
import Textarea from "./textarea";
import ReaderOverlay from "./reader-overlay";
import ToolHeading from "./tool-heading";

import {
  readerPage,
  input,
  textarea,
  button,
} from "./styles/reader.module.scss";

const Reader = ({ path }: { path: string }) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [readerOpen, setReaderOpen] = useState(false);
  const [text, setText] = useState("");

  const openReader = () => {
    const { current } = textareaRef;
    if (current) {
      setText(current.value);
      setReaderOpen(true);
    }
  };

  const closeReader = () => {
    setReaderOpen(false);
  };

  return (
    <div className={readerPage}>
      <ToolHeading toolName="Reader" />
      <div className={input}>
        <Textarea
          ref={textareaRef}
          isFocused
          className={textarea}
          label="Paste text here and push the Transform button"
          spellCheck={false}
        />
        <Button
          id="transform"
          className={button}
          aria-haspopup="dialog"
          onClick={openReader}
        >
          Transform
        </Button>
      </div>
      <ReaderOverlay
        isOpen={readerOpen}
        closeReader={closeReader}
        text={text}
      />
    </div>
  );
};

export default Reader;
