export const getTextNodeAtPosition = (root: Node, index: number) => {
  let position = index;
  const NODE_TYPE = NodeFilter.SHOW_TEXT;
  const treeWalker = document.createTreeWalker(root, NODE_TYPE, {
    acceptNode: (element: any) => {
      if (position > element.textContent.length) {
        position -= element.textContent.length;
        return NodeFilter.FILTER_REJECT;
      }

      return NodeFilter.FILTER_ACCEPT;
    }
  });
  const c = treeWalker.nextNode();
  return {
    node: c ?? root,
    position
  };
};

export const restoreCaretPosition = (context: Node, length: number) => {
  const selection = window.getSelection();

  if (!selection) {
    return;
  }

  const pos = getTextNodeAtPosition(context, length);
  selection.removeAllRanges();
  const newRange = new Range();
  try {
    newRange.setStart(pos.node, pos.position);
    selection.removeAllRanges();
    selection.addRange(newRange);
  } catch (error: unknown) {
    console.error(error);
  }

  // Function scrollIntoViewIfNeeded is not supported in Firefox, so fall back
  // to a slightly more janky option for now
  try {
    pos.node.parentElement?.scrollIntoViewIfNeeded();
  } catch {
    pos.node.parentElement?.scrollIntoView({
      block: 'center',
      inline: 'nearest'
    });
  }
};

export const getCaretPosition = (context: Node) => {
  const selection = window.getSelection();

  if (!selection) {
    return 0;
  }

  try {
    const range = selection.getRangeAt(0).cloneRange();
    range.setStart(context, 0);
    const length = range.toString().length;
    return length;
  } catch {
    return 0;
  }
};
