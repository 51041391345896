import React, {useContext, useEffect, useRef, PropsWithChildren} from 'react';
import ReactDOMServer from 'react-dom/server';
import clsx from 'clsx';
import morphdom from 'morphdom';
import Scrollbar from 'react-scrollbars-custom';
import {ElementPropsWithElementRef} from 'react-scrollbars-custom/dist/types/types';

import {AppContext} from './app-context';
import ArcText from './arc-text';
import {restoreCaretPosition} from '../utils/editor-caret';

import {editor} from './styles/arc-text-editor.module.scss';

type Props = {
  className?: string;
  label?: string;
  width?: number;
  hasFocus?: boolean;
  buffer: {
    text: string;
    caret?: number;
  };
  onInput?: (event: any) => any;
};

const ArcTextEditor = ({
  className,
  label,
  width,
  hasFocus,
  buffer,
  onInput
}: Props) => {
  const editorRef = useRef<HTMLDivElement>(null);

  const {userOptions} = useContext(AppContext);

  useEffect(() => {
    const {current} = editorRef;
    if (current) {
      try {
        current.contentEditable = 'plaintext-only';
      } catch {
        // Firefox doesn't support plaintext-only
        current.contentEditable = 'true';
      }

      if (hasFocus) {
        current.focus({preventScroll: true});
      }
    }
  }, [hasFocus]);

  useEffect(() => {
    // This is slow, but it must be static to be contenteditable
    if (editorRef.current) {
      morphdom(
        editorRef.current,
        ReactDOMServer.renderToStaticMarkup(
          <div>
            <ArcText
              isMarkdown={false}
              id="editor"
              options={userOptions}
              width={width}
              isPrintable={false}
            >
              {buffer.text}
            </ArcText>
          </div>
        ),
        {
          childrenOnly: true,
          onBeforeElUpdated(fromElement, toElement) {
            if (fromElement.isEqualNode(toElement)) {
              return false;
            }

            return true;
          }
        }
      );
    }

    if (buffer.caret && editorRef.current) {
      restoreCaretPosition(editorRef.current, buffer.caret);
    }
  }, [userOptions, width, buffer]);

  return (
    <Scrollbar
      disableTracksWidthCompensation
      className={clsx(className, editor)}
      trackYProps={{
        renderer: ({
          elementRef: ref,
          style,
          ...rest
        }: PropsWithChildren<ElementPropsWithElementRef>) => {
          return (
            <span
              {...rest} ref={ref}
              style={{
                ...style,
                top: '4px',
                right: '4px',
                width: '8px',
                height: 'calc(100% - 8px)',
                background: 'none'}}/>
          );
        }
      }}
    >
      <div
        ref={editorRef}
        aria-label={label}
        role="textbox"
        tabIndex={0}
        onInput={onInput}
      >
        <span/>
      </div>
    </Scrollbar>
  );
};

export default ArcTextEditor;
