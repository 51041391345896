import React, {forwardRef, useEffect, useRef} from 'react';
import clsx from 'clsx';

import {textarea} from './styles/textarea.module.scss';

type Props = {
  className?: string;
  label: string;
  isFocused?: boolean;
  rows?: number;
  spellCheck?: boolean;
};

const Textarea = forwardRef<HTMLTextAreaElement, Props>(
  (
    {className, label, isFocused, rows = 6, spellCheck, ...props}: Props,
    ref
  ) => {
    const localRef = useRef<HTMLTextAreaElement>(null);
    const textareaRef = ref ?? localRef;
    useEffect(() => {
      if (isFocused) {
        (textareaRef as any).current.focus({preventScroll: true});
      }
    }, [textareaRef, isFocused]);
    return (
      <textarea
        ref={textareaRef}
        className={clsx(textarea, className)}
        rows={rows}
        placeholder={label}
        aria-label={label}
        spellCheck={spellCheck}
        {...props}
      />
    );
  }
);

export default Textarea;
