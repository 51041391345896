import React, { useRef, forwardRef, useImperativeHandle } from "react";
import { useWindowSize } from "react-use";
import { useThrottle } from "@react-hook/throttle";
import { ToastContainer, toast } from "react-toastify";
import os from "platform-detect/os.mjs";

import { getCaretPosition } from "../utils/editor-caret";
import ArcTextEditor from "./arc-text-editor";
import ArcText from "./arc-text";
import Button from "./button";
import Toolbar from "./toolbar";
import usePrintArcText from "../hooks/use-print-arc-text";
import ToolHeading from "./tool-heading";

import "react-toastify/dist/ReactToastify.css";
import {
  textBox,
  buttons,
  editor,
  userOptions,
  toast as toast_style,
} from "./styles/writer.module.scss";

const decodeHTML = function (html: string) {
  const txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
};

type TextEditorProps = {
  editorWidth: number;
};

const TextEditor = forwardRef<any, TextEditorProps>(
  ({ editorWidth }: TextEditorProps, ref) => {
    const text = "";

    useImperativeHandle(ref, () => ({
      getText: () => buffer.text,
    }));

    const [buffer, setBuffer] = useThrottle(
      {
        text,
        caret: 0,
      },
      5, // Updates per second (most contenteditable changes are immediately visible without re-rendering)
      true
    );

    const onInput = (event: InputEvent) => {
      const { target } = event;

      if (target) {
        const caret = getCaretPosition(target as HTMLElement);
        const text = ArcText.getTextFromDOM(
          (target as HTMLElement).querySelector("svg") as SVGElement
        );

        setBuffer({ text, caret });
      }
    };

    return (
      <ArcTextEditor
        hasFocus
        buffer={buffer}
        label="Summerbell Writer"
        className={textBox}
        width={editorWidth - 20}
        onInput={onInput}
      />
    );
  }
);

type EditorRef = {
  getText: () => string;
};

type WriterHeaderProps = {
  editorRef?: React.RefObject<EditorRef>;
};

const WriterHeader = ({ editorRef }: WriterHeaderProps) => {
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(
        decodeHTML(editorRef?.current?.getText() ?? " ")
      );
      let copyMessage = "Copied!";
      if (os.windows || os.linux) {
        copyMessage = "Copied! Press Ctrl + V or right\u00A0click to paste.";
      } else if (os.macos) {
        copyMessage = "Copied! Press ⌘ + V or right\u00A0click to paste.";
      }

      toast.info(copyMessage);
    } catch {
      toast.error(
        "Oops! That didn't work. Try selecting the text and right clicking to copy."
      );
    }
  };

  return (
    <ToolHeading toolName="Writer">
      <div className={buttons}>
        <Button
          isSecondary
          id="copyText"
          style={{ fontSize: "0.8em" }}
          onClick={handleCopy}
        >
          Copy Text
        </Button>
      </div>
    </ToolHeading>
  );
};

const Writer = ({ path }: { path: string }) => {
  const editorRef = useRef<EditorRef>(null);
  const { width } = useWindowSize();

  const editorWidth = Math.min(width - 80, 1000);

  const { doPrint, isPrintLoading } = usePrintArcText();

  return (
    <>
      <div className={editor} style={{ width: editorWidth }}>
        <WriterHeader editorRef={editorRef} />
        <TextEditor ref={editorRef} editorWidth={editorWidth} />
      </div>
      <Toolbar
        hasNoWidth
        className={userOptions}
        isPrintLoading={isPrintLoading}
        onPrint={() => {
          const { current } = editorRef;
          if (current) {
            doPrint(current.getText());
          }
        }}
      />
      <ToastContainer
        hideProgressBar
        autoClose={5000}
        position="bottom-center"
        toastClassName={toast_style}
      />
    </>
  );
};

export default Writer;
