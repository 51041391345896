import React from 'react';
import clsx from 'clsx';

import {button, secondary, outline} from './styles/button.module.scss';

type Props = {
  className?: string;
  id?: string;
  children: React.ReactNode[] | React.ReactNode;
  target?: string;
  rel?: string;
  href: string;
  isSecondary?: boolean;
  isOutline?: boolean;
};

const ButtonHref = ({
  className,
  id,
  children,
  target,
  rel,
  href,
  isSecondary,
  isOutline,
  ...props
}: Props) => (
  // eslint-disable-next-line react/jsx-no-target-blank
  <a
    className={clsx(button, className, {
      [secondary]: isSecondary,
      [outline]: isOutline
    })}
    target={target}
    rel={rel}
    href={href}
    id={id}
    {...props}
  >
    {children}
  </a>
);

export default ButtonHref;
