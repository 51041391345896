import React, { useRef, useState, useEffect } from "react";
import { useParams, navigate } from "@reach/router";

import Toolbar from "./toolbar";
import Button from "./button";
import Input from "./input";
import WordHelper from "./word-helper";
import ToolHeading from "./tool-heading";

import {
  wordHelperPage,
  toolbar,
  input,
  button,
  userOptions,
} from "./styles/word-helper-page.module.scss";

const sanitize = (word: string) => word?.replace(/\s/g, "");

const WordHelperPage = ({ path }: { path: string }) => {
  const inputElement = useRef<HTMLInputElement>(null);
  const { query }: { query: string } = useParams();
  const [word, setWord] = useState(sanitize(query));
  const [isFocused, setIsFocused] = useState(true);

  const onSubmit = (event: any) => {
    event.preventDefault();
    setIsFocused(false);
    const { current } = inputElement;
    if (current?.value) {
      const sanitized = sanitize(current.value);
      current.value = sanitized;
      setWord(sanitized);
    }
  };

  useEffect(() => {
    if (word) {
      void navigate(`/tools/wordHelper/${encodeURIComponent(word)}`);
    }
  }, [word]);

  return (
    <div className={wordHelperPage}>
      <ToolHeading toolName="WordHelper" />
      <form name="wordHelper" className={toolbar} onSubmit={onSubmit}>
        <Input
          ref={inputElement}
          isFocused={isFocused}
          name="query"
          className={input}
          defaultValue={word}
          placeholder="Enter a word"
          aria-label="WordHelper word search"
          type="text"
        />
        <Button className={button} type="submit">
          Search
        </Button>
      </form>
      <WordHelper word={word} />
      <Toolbar hasNoWidth className={userOptions} />
    </div>
  );
};

export default WordHelperPage;
