import React, {forwardRef, useEffect, useRef} from 'react';
import clsx from 'clsx';

import {
  label as label_style,
  labelText,
  input,
  input__standalone
} from './styles/input.module.scss';

type Props = {
  type?: string;
  name?: string;
  className?: string;
  wrapperClassName?: string;
  label?: string;
  placeholder?: string;
  defaultValue?: any;
  isFocused?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
};

const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      type = 'text',
      name,
      className,
      wrapperClassName,
      label,
      placeholder,
      defaultValue,
      isFocused,
      isRequired,
      isDisabled,
      ...props
    }: Props,
    ref
  ) => {
    const localRef = useRef(null);
    const inputRef = ref ?? localRef;

    const inputProps = {
      ref: inputRef,
      type,
      name,
      defaultValue,
      'aria-label': label,
      placeholder: placeholder ?? label,
      required: isRequired,
      disabled: isDisabled,
      ...props
    };

    useEffect(() => {
      if (isFocused) {
        (inputRef as any).current.focus({preventScroll: true});
      }
    }, [inputRef, isFocused]);

    return label ? (
      <label className={clsx(label_style, wrapperClassName)}>
        <input className={clsx(input, className)} {...inputProps}/>
        <span className={labelText}>{label}</span>
      </label>
    ) : (
      <input
        className={clsx(input, input__standalone, className)}
        {...inputProps}
      />
    );
  }
);

export default Input;
