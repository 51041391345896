import React from "react";
import Glasses from "../content/icons/glasses.svg";
import Laptop from "../content/icons/laptop.svg";
import Pencil from "../content/icons/pencil.svg";
import Rocket from "../content/icons/rocket.svg";
import IconButtonLink from "./icon-button-link";

import { tools } from "./styles/tools-home.module.scss";

const ToolsHome = ({ path }: { path: string }) => (
  <>
    <h1>Tools</h1>
    <div className={tools}>
      <IconButtonLink
        to="/tools/reader"
        icon={<Glasses style={{ width: "128px" }} />}
      >
        Reader
      </IconButtonLink>
      <IconButtonLink
        to="/tools/writer"
        icon={<Pencil style={{ height: "128px" }} />}
      >
        Writer
      </IconButtonLink>
      <IconButtonLink
        to="/tools/wordHelper"
        icon={<Rocket style={{ height: "128px" }} />}
      >
        WordHelper
      </IconButtonLink>
      <IconButtonLink
        to="/tools/extension"
        icon={<Laptop style={{ width: "128px" }} />}
      >
        Extension
      </IconButtonLink>
    </div>
  </>
);

export default ToolsHome;
