import React from "react";
import { Link } from "gatsby";
import { Menu, MenuButton, MenuLink, MenuList } from "@reach/menu-button";

import Tools from "../content/icons/tools.svg";
import Home from "../content/icons/home.svg";
import Glasses from "../content/icons/glasses.svg";
import Laptop from "../content/icons/laptop.svg";
import Pencil from "../content/icons/pencil.svg";
import Rocket from "../content/icons/rocket.svg";

import {
  heading,
  toolName as toolName_style,
  menuIcon,
  menuButton,
  menuList,
  menuItem,
} from "./styles/tool-heading.module.scss";

type Props = {
  toolName: string;
  children?: React.ReactNode[] | React.ReactNode;
};

const ToolHeading = ({ toolName, children }: Props) => (
  <div className={heading}>
    <Menu>
      <MenuButton className={menuButton}>
        <Tools className={menuIcon} width={32} />
        <h1 className={toolName_style}>{toolName}</h1>
      </MenuButton>
      <MenuList className={menuList}>
        <MenuLink as={Link} className={menuItem} to="/tools">
          <Home width={32} height={32} />
          Home
        </MenuLink>
        <MenuLink as={Link} className={menuItem} to="/tools/reader">
          <Glasses width={64} height={64} />
          Reader
        </MenuLink>
        <MenuLink as={Link} className={menuItem} to="/tools/writer">
          <Pencil width={64} height={64} />
          Writer
        </MenuLink>
        <MenuLink as={Link} className={menuItem} to="/tools/wordHelper">
          <Rocket width={64} height={64} />
          WordHelper
        </MenuLink>
        <MenuLink as={Link} className={menuItem} to="/tools/extension">
          <Laptop width={64} height={64} />
          Extension
        </MenuLink>
      </MenuList>
    </Menu>
    {children}
  </div>
);

export default ToolHeading;
