import React from "react";
import ButtonHref from "./button-href";
import Paragraph from "./paragraph";
import ToolHeading from "./tool-heading";

const Extension = ({ path }: { path: string }) => (
  <div style={{ marginTop: "2em" }}>
    <ToolHeading toolName="Extension" />
    <Paragraph>
      The Summerbell Extension is an add-on you can install in Google Chrome or
      Microsoft Edge to access the Summerbell tools from anywhere on the web.
      You&apos;ll be sent to the Chrome Web Store to install it.
    </Paragraph>
    <ButtonHref
      id="getExtension"
      style={{ display: "block", width: "15em", margin: "0 auto" }}
      target="_blank"
      rel="noreferrer"
      href="/installExtension"
    >
      Get the extension
    </ButtonHref>
  </div>
);

export default Extension;
